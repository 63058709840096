'use strict';
jQuery(document).ready(function ($) {
  //hide video on ended;
  //set body opacity to 1
  $('#main').delay(500).fadeIn(300);

  $('#video').on('ended', function () {
    //fade out video
    $('#video').fadeOut(1000, function () {
      //remove the video after fade out
      $(this).remove();
      $('#section-1').fadeIn(300);
    });
  });
});
